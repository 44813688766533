var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('holders-selection-form',{attrs:{"title":'Select Holder',"holders-list":_vm.holdersList},on:{"update":_vm.updateHolderId},model:{value:(_vm.holder),callback:function ($$v) {_vm.holder=$$v},expression:"holder"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Equity incentive plan',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Equity Incentive Plan","placeholder":"Select Equity incentive plan","items":_vm.eips,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.formData.equity_incentive_plan_id),callback:function ($$v) {_vm.$set(_vm.formData, "equity_incentive_plan_id", $$v)},expression:"formData.equity_incentive_plan_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Share terms',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Share Terms","placeholder":"Select share terms","items":_vm.shareTerms,"item-text":"name","item-value":"id","error-messages":errors},model:{value:(_vm.formData.share_terms_id),callback:function ($$v) {_vm.$set(_vm.formData, "share_terms_id", $$v)},expression:"formData.share_terms_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Granted options',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Granted Options","color":"primary","error-messages":errors,"options":{ locale: 'en-US', precision: 0 }},on:{"input":_vm.calculate},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Exercise price per share',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Exercise Price Per Share","color":"primary","error-messages":errors,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},on:{"input":_vm.calculate},model:{value:(_vm.formData.exercise_price),callback:function ($$v) {_vm.$set(_vm.formData, "exercise_price", $$v)},expression:"formData.exercise_price"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Grant date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Grant date', mandatory: true},"error-messages":errors,"clearable":""},model:{value:(_vm.grantDate),callback:function ($$v) {_vm.grantDate=$$v},expression:"grantDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Expiration date'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Expiration date'},"error-messages":errors,"clearable":""},model:{value:(_vm.expirationDate),callback:function ($$v) {_vm.expirationDate=$$v},expression:"expirationDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"input-templ":{label: 'Board approval date', mandatory: true},"error-messages":errors,"clearable":""},model:{value:(_vm.boardApprovedOn),callback:function ($$v) {_vm.boardApprovedOn=$$v},expression:"boardApprovedOn"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Stock registration name',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Stock Registration Name","color":"primary","error-messages":errors},model:{value:(_vm.stockRegistrationName),callback:function ($$v) {_vm.stockRegistrationName=$$v},expression:"stockRegistrationName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":'Option type'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Option Type (Optional)","placeholder":"Select option type","items":_vm.optionTypes,"item-text":"title","item-value":"value","error-messages":errors},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('common-vesting-form',{model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.optionId,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.OPTIONS,"is-submit":_vm.isSubmit},on:{"navToList":function($event){return _vm.$emit('navToList', true)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }