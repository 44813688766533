<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-app-page-header
        :title="'Draft Option'"
      />
      <div class="trs-form-container-card">
        <common-trs-card class="pa-2 pb-0 mb-2">
          <validation-observer
            ref="observer"
            v-slot="{ valid }"
            class="ml-auto"
          >
            <v-card-text class="pa-0">
              <form>
                <options-form
                  v-model="optionsData"
                  :holders-list="investors"
                  :holder="holder"
                  :share-terms="shareTerms"
                  :eips="eips"
                  :option-id="optionId"
                  :is-submit="isSubmit"
                  @updateHolder="updateHolder"
                  @navToList="navToList"
                />
              </form>
            </v-card-text>
            <v-divider />
            <v-row class="d-flex justify-space-between pa-5">
              <v-col>
                <common-trs-btn
                  type="secondary"
                  medium
                  class="pull-right mr-2"
                  text
                  :to="{name: 'DraftSecurities', query: { tab: 3 }}"
                >
                  Cancel
                </common-trs-btn>
              </v-col>
              <v-col class="text-right">
                <common-trs-btn
                  type="primary"
                  medium
                  class="white--text"
                  text
                  :disabled="!valid"
                  :loading="loading"
                  @click="save"
                >
                  Save
                </common-trs-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </common-trs-card>
      </div>
    </template>
  </common-trs-page-container>
</template>

<script>
  import OptionsForm from '@/components/common/captable/components/forms/OptionsForm'
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'DraftOption',

    components: {
      OptionsForm,
    },

    data: () => ({
      loading: false,
      optionsData: {},
      holder: {},
      eips: [],
      investors: [],
      shareTerms: [],
      optionId: '',
      isSubmit: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },

    watch: {
      internalDialog: {
        handler (val) {
          if (val) {
            this.isSubmit = false
          }
        },
      },
    },

    mounted () {
      this.loadIssuerProfileList()
      this.loadShareTermList()
      this.loadEipList()
    },

    methods: {
      async save () {
        this.loading = true
        if (!this.optionsData.holder_id) {
          this.optionsData.holder_id = await this.createNewInvestor()
        }
        if (this.optionsData.holder_id) {
          if (this.optionsData.vesting_schedule && this.optionsData.vesting_schedule.custom && this.optionsData.vesting_schedule.custom.length > 0) {
            const result = this.optionsData.vesting_schedule.custom.map(item => {
              return { ...item, percent: item.percent / 100 }
            })
            this.optionsData.vesting_schedule.custom = result
          } else {
            if (this.optionsData.vesting_schedule && this.optionsData.vesting_schedule.time_based && this.optionsData.vesting_schedule.time_based.cliff) {
              this.optionsData.vesting_schedule.time_based.cliff = { ...this.optionsData.vesting_schedule.time_based.cliff, percent: this.optionsData.vesting_schedule.time_based.cliff.percent / 100 }
            }
          }
          try {
            this.clearNullData()
            const resp = await captableService.draftOption(this.captableId, this.optionsData)
            if (resp.data.id) {
              this.optionId = resp.data.id
              this.isSubmit = true
            }
          } catch (err) {
            this.loading = false
            this.$store.dispatch('app/handleError', {
              err,
              message: 'Sorry! Unable to draft option. Please try again later.',
            })
          }
        }
      },

      navToList () {
        this.loading = false
        this.$store.dispatch('app/message', { text: 'Option drafted successfully.' })
        this.$router.push({ name: 'DraftSecurities', query: { tab: 3 } })
      },

      async createNewInvestor () {
        try {
          const resp = await captableService.draftProfile(this.captableId, this.holder)
          const id = resp && resp.data.id
          await captableService.publishProfile(this.captableId, id)
          return id
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: `Sorry! Unable to draft share for investor ${this.holder.name}. Please try again later.`,
          })
        }
      },

      updateHolder (val) {
        if (val.to_holder_id) {
          this.optionsData.holder_id = val.to_holder_id
        } else {
          this.optionsData.holder_id = null
          delete val.to_holder_id
          this.holder = val
        }
      },

      async loadIssuerProfileList () {
        try {
          const resp = await captableService.getIssuerProfileList(this.captableId)
          this.investors = resp.data.profiles
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
      async loadShareTermList () {
        try {
          const resp = await captableService.getShareTermList(this.captableId)
          this.shareTerms = resp.data.share_terms
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },
      async loadEipList () {
        try {
          const resp = await captableService.getEipList(this.captableId)
          if (resp && resp.data) {
            this.eips = resp.data.equity_incentive_plans
          }
        } catch (e) {}
      },
      clearNullData () {
        Object.keys(this.optionsData).forEach((key) => {
          if (this.optionsData[key] === null || this.optionsData[key] === undefined) {
            delete this.optionsData[key]
          }
          if (this.optionsData.vesting_schedule) {
            Object.keys(this.optionsData.vesting_schedule).forEach((key) => {
              if (this.optionsData.vesting_schedule[key] === undefined || this.optionsData.vesting_schedule[key] === null || Object.entries(this.optionsData.vesting_schedule[key]).length === 0) {
                delete this.optionsData.vesting_schedule[key]
              }
            })
          }
        })
      },
    },
  }
</script>
